import Vue from "vue";

export default {
  
  
  created() {
    if (this.$route.query.code) {
      const { origin } = new URL(document.URL);
      this.$http
        .post(
          `/auth/login?code=${this.$route.query.code}&hostUrl=${origin}`
        )
        .then((response) => {
          console.log(response);

          const { userData } = response.data;

          // Setting user data in localStorage
          localStorage.setItem("userData", JSON.stringify(userData));
        })
        .finally(() => this.$router.replace("/"));
    } else {
      this.$router.replace("/not-authorized");
    }
  },
};

